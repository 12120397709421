import React, { useState, useEffect } from 'react'
import { graphql, Link } from 'gatsby'

import Select from 'react-select'

import classNames from 'classnames'

import SEO from '~/components/seo'
import ProductGrid from '~/components/ProductGrid'

import { sortOn, priceSort } from '~/utils/helpers'

// import ProductForm from '~/components/ProductForm'
// import {
//   Img,
//   Container,
//   TwoColumnGrid,
//   GridLeft,
//   GridRight,
// } from '~/utils/styles'

import {
  Filter,
  selectStyle,
  Products,
  Side,
  SideCategories,
  ProductGridWrap,
} from './styles'

const Collection = ({ data }) => {
  const { title, products, handle } = data.shopifyCollection
  const { body: items } = data.allPrismicCollections.edges[0].node.data

  const [filter, setFilter] = useState('alphabetically')
  const [filteredProducts, setFilteredProducts] = useState(products)

  useEffect(() => {
    let newProducts = [...filteredProducts]

    if (filter === 'alphabetically') {
      newProducts.sort(sortOn('title'))
    } else if (filter === 'price-low') {
      newProducts.sort(priceSort('ASC'))
    } else if (filter === 'price-high') {
      newProducts.sort(priceSort('DESC'))
    }

    setFilteredProducts(newProducts)
  }, [filter])

  const options = [
    {
      value: 'alphabetically',
      label: 'Alphabetically',
    },
    {
      value: 'price-low',
      label: 'Price (low to high)',
    },
    {
      value: 'price-high',
      label: 'Price (high to low)',
    },
  ]

  const handleOptionChange = event => {
    setFilter(event.value)
  }

  const MenuItem = ({ id, title, hasSubmenu, active, level, submenu }) => {
    const levelString = `level-${level}`
    var classes = classNames({
      active: active,
      [levelString]: levelString,
      // 'activeClass': self.state.focused === index
    })

    // console.log(classes)

    return (
      <li className={classes}>
        <Link to={`/collection/${id}`}>{title}</Link>
        {submenu && submenu.length > 0 && renderCollectionsStructured(submenu)}
      </li>
    )
  }

  const renderCollectionsStructured = items => {
    const menuOptions = items.map(item => {
      return (
        <MenuItem
          key={item.collection_id}
          id={item.collection_id}
          title={item.collection_title}
          active={item.collection_active}
          level={item.collection_level}
          hasSubmenu={
            item.collection_submenu && item.collection_submenu.length > 0
              ? true
              : false
          }
          submenu={item.collection_submenu}
        />
      )
    })

    return <ul>{menuOptions}</ul>
  }

  const customFilter = (object, key, value) => {
    if (object !== null) {
      if (object.hasOwnProperty([key]) && object[key] === value) return object

      for (var i = 0; i < Object.keys(object).length; i++) {
        if (typeof object[Object.keys(object)[i]] == 'object') {
          var o = customFilter(object[Object.keys(object)[i]], key, value)
          if (o != null) return o
        }
      }
    }

    return null
  }

  const setActiveParents = object => {
    let activeParentIndex = customFilter(object, 'collection_active', true)
    if (activeParentIndex) {
      activeParentIndex = activeParentIndex.collection_parent
    }

    if (activeParentIndex) {
      const activeParent = customFilter(
        object,
        'collection_index',
        activeParentIndex
      )
      activeParent.collection_active = true
      setActiveParents(object)
    }
    return object
  }

  const renderCollections = items => {
    // console.log(items)

    let menu = []
    let firstLevelCount = -1

    let currentLevel1
    let currentLevel2

    items.forEach((item, i) => {
      let index = i + 1
      let itemObject = {}
      itemObject.collection_id = item.primary.collection_id
      itemObject.collection_title = item.primary.collection_title
      itemObject.collection_index = index
      itemObject.collection_active =
        itemObject.collection_id === handle ? true : false

      if (item.slice_type === '1st_level') {
        currentLevel1 = index
        itemObject.collection_parent = false
        itemObject.collection_submenu = []
        itemObject.collection_level = 1
        menu.push(itemObject)
        firstLevelCount++
      } else if (item.slice_type === '2nd_level') {
        currentLevel2 = index
        itemObject.collection_level = 2
        itemObject.collection_parent = currentLevel1

        // console.log(item)

        if (item.items && item.items.length > 0) {
          itemObject.collection_submenu = item.items.map(field => {
            field.collection_parent = currentLevel2
            field.collection_level = 3
            field.collection_active =
              field.collection_id === handle ? true : false
            return field
          })
        }
        menu[firstLevelCount].collection_submenu.push(itemObject)
      }
    })

    const activatedMenu = setActiveParents(menu)

    const menuItems = renderCollectionsStructured(activatedMenu)

    // console.log(menu)

    // console.log(customFilter(menu, 'collection_active', true))

    return menuItems
  }

  return (
    <>
      <SEO title={title} />
      {/* <h1>{title}</h1> */}

      <Products>
        <Side>
          <Filter>
            <label>Sort by: </label>

            <Select
              options={options}
              name="sort"
              onChange={event => handleOptionChange(event)}
              // placeholder="Select format"
              styles={selectStyle}
              isSearchable={false}
              defaultValue={options[0]}
            />
          </Filter>
          <SideCategories>{renderCollections(items)}</SideCategories>
        </Side>

        <ProductGridWrap>
          <ProductGrid products={filteredProducts} />
        </ProductGridWrap>
      </Products>

      {/* {products.map(product => (
        <div key={product.shopifyId} className="col-md-4">
          <div className="card card-product-grid">
            <div className="img-wrap">
              <img src={product.images[0].originalSrc} alt={product.handle} />
            </div>
            <a href="#" className="title">
              {product.title}
            </a>
            <div className="mt-2 price-wrap">
              <span className="price">
                ${product.priceRange.minVariantPrice.amount}
              </span>
            </div>
          </div>
        </div>
      ))} */}
    </>
  )
}

export const query = graphql`
  query($handle: String!) {
    shopifyCollection(handle: { eq: $handle }) {
      id
      title
      handle
      products {
        id
        title
        handle
        createdAt
        priceRange {
          maxVariantPrice {
            amount
          }
          minVariantPrice {
            amount
          }
        }
        images {
          id
          originalSrc
        }
        variants {
          price
        }
      }
    }
    allPrismicCollections {
      edges {
        node {
          data {
            body {
              ... on PrismicCollectionsBody1stLevel {
                primary {
                  collection_title
                  collection_id
                }
                slice_type
              }
              ... on PrismicCollectionsBody2ndLevel {
                primary {
                  collection_id
                  collection_title
                }
                items {
                  collection_title
                  collection_id
                }
                slice_type
              }
            }
          }
        }
      }
    }
  }
`

export default Collection
