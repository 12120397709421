import React from 'react'

export function sortOn(key) {
  return function(a, b) {
    if (a[key] > b[key]) return 1
    if (a[key] < b[key]) return -1
    return 0
  }
}

export function priceSort(way) {
  const number = way === 'ASC' ? 1 : -1
  return function(a, b) {
    // console.log(a.priceRange.minVariantPrice, b.priceRange.minVariantPrice)
    if (
      parseFloat(a.priceRange.minVariantPrice.amount) >
      parseFloat(b.priceRange.minVariantPrice.amount)
    )
      return number
    if (
      parseFloat(a.priceRange.minVariantPrice.amount) <
      parseFloat(b.priceRange.minVariantPrice.amount)
    )
      return number * -1
    return 0
  }
}
