import styled from '@emotion/styled'
import { breakpoints } from '../../utils/styles'

export const Filter = styled.div`
  margin: 0 0 4rem;
  display: flex;
  align-items: center;
  label {
    font-size: 1.6rem;
    text-transform: uppercase;
    margin-right: 1em;
  }
`

export const Products = styled.div`
  margin: 4rem 0;
  @media (min-width: ${breakpoints.l}px) {
    display: flex;
  }
`

export const Side = styled.div`
  @media (min-width: ${breakpoints.l}px) {
    width: 22rem;
  }
`

export const SideCategories = styled.div`
  display: none;
  @media (min-width: ${breakpoints.l}px) {
    display: block;
  }
  ul {
    list-style: none;
    padding: 0;
    ul {
      padding: 0 0 0 2rem;
    }
  }
  li {
    text-transform: uppercase;
    &.active {
      > a {
        /* background-color: rgba(255, 255, 255, 0.1); */
        font-weight: bold;
      }
    }
  }
  a {
    display: block;
    padding: 1rem 0;
  }

  .level-1 {
    display: none;
    &.active {
      display: block;
    }
    > a {
      display: none;
    }
    > ul {
      padding-left: 0;
    }
  }
  .level-2 {
    > ul {
      display: none;
    }
    &.active {
      > ul {
        display: block;
      }
    }
  }
`

export const ProductGridWrap = styled.div`
  width: 100%;
  @media (min-width: ${breakpoints.l}px) {
    width: calc(100% - 22rem);
  }
`

export const selectStyle = {
  menu: (provided, state) => ({
    ...provided,
    // width: state.selectProps.width,
    border: '1px solid white',
    color: 'white',
    padding: 0,
    backgroundColor: '#525252',
    borderRadius: 0,
  }),

  control: () => ({
    // width: width,
    backgroundColor: 'transparent',
    color: 'white',
    // height: '5.3rem',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    width: '13rem',
  }),

  singleValue: () => ({
    color: 'white',
    textTransform: 'uppercase',
    fontSize: '1.6rem',
    height: '1em',
    fontWeight: 'bold',
  }),

  valueContainer: () => ({
    padding: 0,
    height: '2rem',
  }),

  placeholder: () => ({
    color: 'white',
    textTransform: 'uppercase',
    fontSize: '1.6rem',
  }),

  indicatorsContainer: () => ({
    display: 'none',
    border: 0,
    color: 'white',
  }),

  option: (provided, state) => ({
    backgroundColor: state.isFocused ? 'white' : 'transparent',
    color: state.isFocused ? '#525252' : 'white',
    padding: '1rem',
    fontSize: '1.6rem',
    textTransform: 'uppercase',
    cursor: 'pointer',
  }),

  // singleValue: (provided, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1
  //   const transition = 'opacity 300ms'
  //   return { ...provided, opacity, transition }
  // },
}

// import styled from '@emotion/styled'

// export const ProductTitle = styled.h1`
//   font-size: 2.25rem;
//   margin-bottom: 15px;
//   word-wrap: break-word;
//   font-family: "Helvetica","Helvetica",sans-serif;
//   font-weight: 400;
//   margin: 0 0 0.5rem;
//   line-height: 1.4;
// `

// export const ProductDescription = styled.div`
//   margin-top: 40px;
//   font-family: "Helvetica","Helvetica",sans-serif;
//   font-weight: 300;
// `
